import type { MaybeRefOrGetter } from 'vue'
import type { IColumn, IReconciliation } from '../../types'
import { createColumnHelper } from '@tanstack/vue-table'
export const useScheduleComparisonColumns = (
  columns: MaybeRefOrGetter<IColumn[]>,
) => {
  const columnHelper = createColumnHelper<IReconciliation>()
  return computed(() =>
    toValue(columns)
      .filter(
        (col) =>
          !col.dataFieldName.toLowerCase().includes('leaseid') &&
          !col.dataFieldName.toLowerCase().includes('lease_id') &&
          !col.dataFieldName.toLowerCase().includes('leasename') &&
          !col.dataFieldName.toLowerCase().includes('lease_name'),
      )
      .map((col) =>
        columnHelper.display({
          id: col.dataFieldName,
          header: splitByCapitalLetter(col.displayName),
        }),
      ),
  )
}
